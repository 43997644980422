import React from 'react';

const Report = () => (
  <svg id="sl_svg1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.1 8.8">
    <path
      className="sl_svg1_st0"
      d="M7.8 7V1.6M6 7V3.7M4.1 7V1.6M2.3 7V3.7M9.7 8.4H.3V.3"
    />
  </svg>
);

export default Report;
