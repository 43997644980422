import React from 'react';
import { Link } from 'react-router-dom';
import MiniLogo from '../media/svg/MiniLogo';
import CopyRight from './CopyRight';

const TermsOfUse = () => {
  return (
    <div className="master lightOn" id="master">
      <div className="textContainer">
        <div className="header">
          <div className="header_inner">
            <Link to="/">
              <MiniLogo />
            </Link>
          </div>
        </div>
        <div className="termsOfUse">
          <h1>Terms Of Use</h1>
          <div className="row">
            <div className="column">
              <div id=":mj" className="a3s aXjCH ">
                <p>Updated: January 24, 2019 </p>
                <p>
                  These terms of use (“<b>Terms</b>”), and any policies
                  incorporated by reference, are a legally binding contract
                  between Simple Farms LLC (“<b>Simple Farms</b>” or “<b>we</b>”
                  or “<b>us</b>”) and you. These Terms are applicable if you
                  visit Simple Farms’ website (
                  <u>
                    <a
                      href={`${window.location.href}`}
                      data-saferedirecturl={`https://www.google.com/url?q=${
                        window.location.href
                      }&amp;source=gmail&amp;ust=1547572672017000&amp;usg=AFQjCNHoVItXLAcklnX8lT-XAoE4csQyAQ`}
                    >
                      https://simplefarms.ag
                    </a>
                  </u>
                  ) (the “<b>Site</b>”), download or use Simple Farms’ mobile
                  application(s) (the “<b>Apps</b>”), or use Simple Farms’
                  services (which may be available through the Site or the Apps)
                  (the “<b>Services</b>”).
                </p>
                <ol>
                  <li>Accepting the Terms</li>
                </ol>
                <p>
                  By clicking “accept” or by using or accessing the Site, Apps,
                  or Services, you expressly agree to be bound by the terms,
                  conditions, policies and guidelines of these Terms. If you do
                  not agree or consent to any of the provisions of these Terms,
                  your only recourse is to discontinue accessing or otherwise
                  using the Site, Apps, or Services. Do not use the Site, Apps,
                  or Services if you do not agree with these Terms.
                </p>
                <ul>
                  <li>Privacy</li>
                </ul>
                <p>
                  You can review Simple Farms’ Privacy Policy here. You agree to
                  the Privacy Policy and any changes thereto published by Simple
                  Farms. You agree that Simple Farms may use and maintain your
                  data according to the Privacy Policy. You give Simple Farms
                  permission to combine information you enter and upload on the
                  Site or Apps or for the Services with that of other users.{' '}
                </p>
                <ul>
                  <li>Description of the Services</li>
                </ul>
                <p>
                  Simple Farms, through its Apps and Site, offers businesses’
                  and individuals’ access to various Services. The Services are
                  generally of use to persons engaged in agricultural
                  businesses.{' '}
                </p>
                <ul>
                  <li>Purchase of Services</li>
                </ul>
                <p>
                  While access to certain limited portions of the Site, Apps,
                  and Services are or may be free, Simple Farms charges fees for
                  the provision of the majority of its Services offered through
                  the Site and Apps. When you purchase the Services, you will be
                  required to agree to a separate binding agreement between you
                  and Simple Farms (a “<b>Service Agreement</b>”). A Service
                  Agreement will supplement and control over these Terms.
                  Contact Simple Farms using the information available on the
                  Site or (if available) through the Apps to inquire about
                  purchasing Services.
                </p>
                <ul>
                  <li>Account Registration</li>
                </ul>
                <p>
                  By creating an account with Simple Farms, you agree to provide
                  us with complete and accurate information and to keep this
                  information up to date. This may include, but is not limited
                  to, your name, email address, telephone number, company name,
                  company website, directory listings, social media profiles,
                  password, and credit card information.
                </p>
                <p>
                  You are solely responsible for activities that occur under or
                  through your account, including activities initiated by third
                  parties, whether or not such activities are authorized by you.
                  Should you grant a third party to act on your behalf, you
                  shall ensure that third party is bound by, and abides by,
                  these Terms. You agree to immediately notify Simple Farms of
                  any unauthorized use of your account or any breach of your
                  account security.
                </p>
                <ul>
                  <li>Eligibility</li>
                </ul>
                <p>
                  The Services are intended for use by adults and not children.
                  The Services are not available users who have had their
                  account temporarily or permanently deactivated. By becoming a
                  user, or registering an account with Simple Farms, you
                  represent and warrant that you are at least 18&nbsp;years old
                  and that you have the right, authority, and capacity to enter
                  into and abide by these Terms.{' '}
                </p>
                <ul>
                  <li>Modifications</li>
                </ul>
                <p>
                  Simple Farms reserves the right to revise these Terms,
                  including information incorporated by reference or referenced
                  in the hyperlinks, at its sole and absolute discretion, by
                  updating this posting. If changes are material, we will post a
                  banner and a link on the Site notifying you of the material
                  change. The revised terms will take effect the date they are
                  posted on the Site. Continued use of the Site, Apps, or
                  Services after any such changes shall constitute your consent
                  to such modifications. Please review these posted terms on a
                  regular basis.
                </p>
                <p>
                  Simple Farms reserves the right to, at any time and without
                  notice, limit access to, modify, change or discontinue the
                  Site, Apps, or Services, subject to the Service Agreement (if
                  any) between you and Simple Farms. Except as set forth in an
                  applicable Service Agreement, Simple Farms will not be liable
                  to you or to any third party for any such limitation,
                  modification, change, suspension, or discontinuance of the
                  Site, Apps, or Services. You agree that Simple Farms may
                  establish general practices, policies, and limits, which may
                  or may not be published, concerning the use or operation of
                  the Site, Apps, or Services.
                </p>
                <ul>
                  <li>Required Hardware and Services</li>
                </ul>
                <p>
                  Your use of the Site, Apps, or Services may require that you
                  own or have access to a computer, mobile device, high-speed
                  internet and/or network access, and third-party software,
                  among other things. You are solely responsible for these
                  requirements, including any applicable changes, updates, and
                  fees, as well as the terms of any agreements with providers or
                  suppliers of such requirements. Simple Farms makes no
                  representations or warranties of any kind, express, statutory,
                  or implied, as to: (a)&nbsp;the availability of
                  telecommunications services or access to the Site, Apps, or
                  Services at any time or from any location, (b)&nbsp;any loss,
                  damage, or other security intrusion of any telecommunication
                  service or of any software not provided by Simple Farms, or
                  (c)&nbsp;any disclosure of information to third parties or
                  failure to transmit any data, communication, or settings by a
                  telecommunications service provider or provider of non-Simple
                  Farms software.
                </p>
                <ul>
                  <li>Online and Mobile Alters</li>
                </ul>
                <p>
                  Simple Farms may from time to time provide automatic alters
                  and voluntary account-related alerts. The alerts may be delays
                  or prevented by a variety of factors. Simple Farms does not
                  guarantee, and you should not solely rely on, the delivery,
                  timeliness, or accuracy of the content of any later. Simple
                  Farms shall not be liable for: (a)&nbsp;any delayed or failed
                  alert; (b)&nbsp;errors in the content of any alter; or
                  (c)&nbsp;any actions taken or not taken by you or any third
                  party in reliance on an alert.
                </p>
                <ul>
                  <li>Rights to Your Information or Data</li>
                </ul>
                <p>
                  By submitting information or data to the Site, Apps, or
                  through the Services, you granting Simple Farms the right and
                  license to use that content to provide Services to you in
                  accordance with these Terms, the Service Agreement (if
                  applicable), and the Privacy Policy. You represent and warrant
                  that you are entitled to submit such content to Simple Farms
                  for this purpose, without any obligation by Simple Farms to
                  pay any fees or be subject to restrictions or limitations.
                </p>
                <ul>
                  <li>Simple Farms’ Intellectual Property Rights</li>
                </ul>
                <p>
                  The Site, Apps, and contents of the Services, including their
                  “look and feel” (<i>e.g.</i>, text, graphics, images, logos,
                  and button icons), photographs, editorial content, notices,
                  software (including html-based computer programs) and other
                  material are protected under both United States and other
                  applicable copyright, trademark, and other laws. The Site,
                  Apps, and contents of the Services belong or are licensed to
                  Simple Farms or its software or content suppliers. Simple
                  Farms grants you the right to view and use the Site, Apps, and
                  Services subject to these terms. Any distribution, reprint, or
                  electronic reproduction of any content from the Services in
                  whole or in part for any purpose is expressly prohibited
                  without our prior written consent. You agree not to use, nor
                  permit any third party to use, the Site, Apps, or the Services
                  or content in a manner that violates any applicable law,
                  regulation, or these Term or any Service Agreement to which
                  you are bound.
                </p>
                <ul>
                  <li>Access and Interference</li>
                </ul>
                <p>You agree that you will not:</p>
                <ul className="m_-8938396142927294103small">
                  <li>
                    Use any robot, spider, scraper, deep link, or other similar
                    automated data gathering or extraction tools, program,
                    algorithm, or methodology to access, acquire, copy, or
                    monitor the Site, Apps, or Services or any portion thereof,
                    without Simple Farms’ express written consent, which may be
                    withheld in Simple Farms’ sole discretion;
                  </li>
                </ul>
                <ul>
                  <li>
                    Use or attempt to use any engine, software, tool, agent, or
                    other device or mechanism (including without limitation
                    browsers, spiders, robots, avatars, or intelligent agents)
                    to navigate or search the Site, Apps, or Services, other
                    than the search engines and search agents available through
                    the Site, Apps, or Services and other than generally
                    available third-party web browsers (such as Internet
                    Explorer or Safari);
                  </li>
                  <li>
                    Post or transmit any file which contains viruses, worms,
                    Trojan horses, or any other contaminating or destructive
                    features, or that otherwise interfere with the proper
                    working of the Site, Apps, or Services;
                  </li>
                  <li>
                    Attempt to decipher, decompile, disassemble, or
                    reverse-engineer any of the software comprising or in any
                    way making up a part of the Site, Apps, or Services; or
                  </li>
                  <li>
                    Attempt to gain an unauthorized access to any portion of the
                    Site, Apps, or Services.
                  </li>
                  <li>Rules for Posting</li>
                </ul>
                <p>
                  Simple Farms may allow you to post content on bulletin boards,
                  blogs, and at various other available locations on the Site or
                  Apps. These locations may be publically available or
                  restricted to only users who have purchased the Services.
                  These forums may be hosted by Simple Farms or by one of our
                  third party service providers on Simple Farms’ behalf. You
                  agree in posting content to follow certain rules.
                </p>
                <ul className="m_-8938396142927294103small">
                  <li>
                    You are responsible for all content you submit, upload, post
                    or store through the Services.
                  </li>
                </ul>
                <ul>
                  <li>
                    You grant Simple Farms a worldwide, royalty-free,
                    non-exclusive license to host and use any content you post.
                    Simple Farms is not responsible for liable for any lost or
                    unrecoverable content you post. By submitting content, you
                    represent that you have all necessary rights to submit the
                    content. You also hereby grant each other user of the Site
                    or Apps a non-exclusive license to access your posted
                    content and to use, reproduce, distribute, prepare
                    derivative works of, display and perform such content as
                    permitted through the functionality of the Services.
                  </li>
                  <li>
                    You agree not to use, nor permit any third party to use, the
                    Site, Apps, or Services to: (a)&nbsp;post or transmit any
                    content which is libelous or defamatory, or which discloses
                    private or personal matters concerning any person;
                    (b)&nbsp;post or transmit any content (i)&nbsp;that is
                    indecent, obscene, pornographic, harassing, threatening,
                    abusive, hateful, racially, or ethnically offensive,
                    (ii)&nbsp;that encourages conduct that would be considered a
                    criminal offense, give rise to civil liability or violate
                    any law; or (iii)&nbsp;that is otherwise inappropriate;
                    (c)&nbsp;post or transmit any content that would violate the
                    property rights of others, including unauthorized
                    copyrighted text, images, or programs, trade secrets, or
                    other confidential proprietary information, and trademarks
                    or service marks used in an infringing fashion; or
                    (d)&nbsp;interfere with other users’ use of the Site, Apps,
                    or Service, including, without limitation,
                    (i)&nbsp;disrupting the normal flow of dialogue in an
                    interactive area of the Site or Apps, (ii)&nbsp;deleting or
                    revising any content posted by another person or entity, or
                    (iii)&nbsp;taking any action that imposes a disproportionate
                    burden on the infrastructure of the Site, Apps, or Service
                    or that negatively affects the availability of the Site,
                    Apps, or Service to others.
                  </li>
                  <li>
                    You agree that we may use any content or feedback you post
                    in any way, including in future modifications of the Site,
                    Apps, or Service, other products or services, or advertising
                    or marketing materials. You grant us a perpetual, worldwide,
                    fully transferable, sublicensable, non-revocable, fully
                    paid-up, royalty free license to use the content and
                    feedback you provide to us in any way.
                  </li>
                  <li>Disclaimer of Representations and Warranties</li>
                </ul>
                <p>
                  The Site, Apps, and Services, and all content, services, and
                  products associated or provided through the Site, Apps, and
                  Services, are provided on an “as-is” and “as available” basis.
                  Simple Farms and its affiliates, third party providers,
                  licensors, distributors, and suppliers (collectively,
                  “Suppliers”) make no representations or warranties of any
                  kind, express or implied, as to the content or operation of
                  the Site, Apps, or Services. You expressly agree that your use
                  of the Site, Apps, and Services is at your sole risk.
                </p>
                <p>
                  Simple Farms and its Suppliers make no representations,
                  warranties or guarantees, express or implied, regarding the
                  accuracy, reliability, or completeness of the content on the
                  Site, apps, or of the Services, and expressly disclaims any
                  warranties of non-infringement or fitness for a particular
                  purpose. Simple Farms and its Suppliers make no
                  representation, warranty, or guarantee that the content that
                  may be available through the services is free of code or
                  computer programming routines that are intended to damage,
                  surreptitiously intercept, or expropriate any system, data or
                  personal information.
                </p>
                <p>
                  Except as set forth in a Service Agreement between you and
                  Simple Farms, Simple Farms and its Suppliers make no guarantee
                  concerning the availability of the Site, Apps, or Services.{' '}
                </p>
                <p>
                  Simple Farms disclaims any liability for inaccuracies or
                  errors in the Sites, Apps, or Services, or the content,
                  services, or products associated or provided through the Site,
                  Apps, or Services.
                </p>
                <ul>
                  <li>Not a Financial Planner, Broker, or Tax Advisor</li>
                </ul>
                <p>
                  Neither Simple Farms nor the Services are intended to provide
                  legal, tax, or financial advice. Simple Farms is not a
                  financial planner, broker, or tax advisor. The Services are
                  intended only to assist you in your financial organization and
                  decision-making and is broad in scope. Your financial
                  situation is unique, and any information and advice obtained
                  through the Service may not be appropriate for your situation.
                  Accordingly, before making any final decisions or implementing
                  any financial or business strategy, you should consider
                  obtaining additional information and advice from your
                  accountant or other financial advisers who are fully aware of
                  your business circumstances.
                </p>
                <ul>
                  <li>Limitations on Simple Farms’ Liability</li>
                </ul>
                <p>
                  Simple Farms shall in no event be responsible or liable to you
                  or to any third party, whether in contract, warranty, tort
                  (including negligence) or otherwise, for any{' '}
                </p>
                <p>
                  (1)&nbsp;indirect, special, incidental, consequential,
                  exemplary, liquidated, or punitive damages, or{' '}
                </p>
                <p>
                  (2)&nbsp;lost profits, lost revenue, or lost business
                  (regardless of whether characterized as direct, indirect,
                  consequential, or another type of damages){' '}
                </p>
                <p>arising in whole or in part from:</p>
                <p>(a)&nbsp;your access to or use of the Site, </p>
                <p>(b)&nbsp;your download or use of the Apps, </p>
                <p>(c)&nbsp;your use of the Services, or </p>
                <p>(d)&nbsp;these Terms. </p>
                <p>
                  Simple Farms’ liability to you for any cause whatever and
                  regardless of the form of the action will at all times be
                  limited to a maximum of $500.00.{' '}
                </p>
                <p>
                  In no event will Simple Farms be liable for your attorneys’
                  fees or other costs associated with any dispute between you
                  and Simple Farms, and you agree not to claim, seek, or collect
                  the same from Simple Farms.
                </p>
                <ul>
                  <li>Your Indemnification of Simple Farms</li>
                </ul>
                <p>
                  You shall defend, indemnify, and hold harmless Simple Farms
                  and its officers, directors, equity owners, and employees,
                  from and against all claims, suits, proceedings, losses,
                  liabilities, and expenses (including but not limited to
                  attorneys’ fees), whether in tort, contract, or otherwise,
                  that arise out of or relate in whole or in part arising out of
                  or attributable to your breach of these Terms or any activity
                  by you in relation to the Site or Apps or your use of the
                  Services.
                </p>
                <ul>
                  <li>Mobile App Store</li>
                </ul>
                <p>
                  This section applies to any Apps that you acquire from a
                  Mobile App Store. Apple Inc., Google, Inc., Microsoft
                  Corporation, or BlackBerry Limited, or other similar
                  third-party you use to download Apps (a “
                  <b>Mobile App Store Provider</b>”) are not a party to these
                  Terms and shall have no obligations with respect to the Site,
                  Apps, or Services. Simple Farms, not a Mobile App Store
                  Provider, is responsible for the Site, Apps, and Services. You
                  warrant that you are not located in a country subject to a
                  U.S. government embargo or designed as a terrorist-supporting
                  country and are not listed on any U.S. government list of
                  prohibited or restricted parties. To the furthest extent
                  permitted by law, the Mobile App Store Providers do not make
                  any representation or warranty concerning the Apps or the
                  Service and will not have any liability to you related to the
                  Site, Apps, or Service. Mobile App Store Providers are
                  third-party beneficiaries of these Terms.
                </p>
                <ul>
                  <li>Governing Law and Forum for Disputes</li>
                </ul>
                <p>
                  These Terms shall be governed by the laws of the State of
                  Colorado, without regard to conflicts of law principles. Any
                  action relating to these Terms must be filed and maintained in
                  a state or federal court of competent jurisdiction located in
                  Denver, Colorado, and you consent to exclusive jurisdiction
                  and venue in such courts for such purpose. Simple Farms makes
                  no representation that these Terms and such practices comply
                  with the laws of any country outside the United States.
                </p>
                <p>
                  Any action, suit, or proceeding arising out of or in
                  connection with these Terms shall be in your and Simple Farms’
                  individual capacity and not as a plaintiff or class member in
                  any purported class or representative proceeding. You may not
                  seek to have any dispute heard as a class action, a
                  representative action, or in any proceeding in which you or
                  Simple Farms acts or proposes to act in a representative
                  capacity. A judge may not consolidate or join the claims of
                  other persons or parties who may be similarly situated without
                  the written consent of you, Simple Farms, and all other
                  impacted parties.{' '}
                </p>
                <p>
                  If any provision of these Terms is held to be invalid or
                  unenforceable, all remaining provisions hereof will remain in
                  full force and effect.{' '}
                </p>
                <ul>
                  <li>Intellectual Property Complaints</li>
                </ul>
                <p>
                  In accordance with the Digital Millennium Copyright Act of
                  1998, Title 17 of the United States Code, Section 512 (the “
                  <b>DMCA</b>”), Simple Farms will respond promptly to claims of
                  copyright or trademark infringement that are reported to the
                  agent that we have designated to receive notifications of
                  claims infringement.
                </p>
                <p>
                  If you are a copyright or trademark owner (or authorized to
                  act on behalf of the copyright or trademark owner) and believe
                  that your work’s copyright or trademark has been infringed,
                  please report your notice of infringement to us with a written
                  notification of claimed infringement that includes
                  substantially the following:
                </p>
                <ul>
                  <li>
                    An electronic or physical signature of the person authorized
                    to act on behalf of the owner of the copyright interest;{' '}
                  </li>
                  <li>
                    A description of the copyrighted work that you claim has
                    been infringed upon;{' '}
                  </li>
                  <li>
                    A description of where the material that you claim is
                    infringing is located on the Site or Apps;{' '}
                  </li>
                  <li>
                    Your contact information, including your address, telephone
                    number, and e-mail address;{' '}
                  </li>
                  <li>
                    A statement by you that you have a good-faith belief that
                    the disputed use is not authorized by the copyright owner,
                    its agent, or the law.
                  </li>
                </ul>
                <p>
                  Simple Farms will investigate notices of copyright and
                  trademark infringement and take appropriate actions under the
                  DMCA. Inquiries that do not follow this procedure may not
                  receive a response.
                </p>
                <p>
                  Send all DMCA and intellectual property related notices or
                  claims to:
                </p>
                <p>Attn: DMCA Agent P.O. Box 801 Cheyenne Wells, CO 80810</p>
                <ul>
                  <li>General</li>
                </ul>
                <p>
                  No waiver by Simple Farms of any term or condition set out in
                  these Terms shall be deemed a further or continuing waiver of
                  such term or condition or a waiver of any other term or
                  condition, and any failure of Simple Farms to assert a right
                  or provision under these Terms shall not constitute a waiver
                  of such right or provision.
                </p>
                <p>
                  If any provision of these Terms held to invalid or
                  unenforceable, such provision shall be reformed only to the
                  extent necessary to make it enforceable and if such provision
                  is determined to be invalid or unenforceable nonetheless, the
                  provision shall be deemed to be severable from the remainder
                  of these Terms and shall not cause the invalidity or
                  unenforceability of the remainder of these Terms.
                </p>
                <p>
                  These Terms (along with the Privacy Policy and any Service
                  Agreements, if applicable) constitute the entire agreement
                  between you and Simple Farms and supersede all prior and
                  contemporaneous understandings, agreements, representations,
                  and warranties, both written and oral, regarding the Site,
                  Apps, or Services. Notwithstanding the preceding sentence,
                  should there be a conflict between these Terms and the Service
                  Agreement, the Service Agreement shall govern.{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <CopyRight />
      </div>
    </div>
  );
};

export default TermsOfUse;
