import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from './components/Home';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfUse from './components/TermsOfUse';
import ServiceAgreement from './components/ServiceAgreement';
import './App.css';

class App extends Component {
  render() {
    return (
      <Router>
        <>
          <Route path="/" exact component={Home} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms-of-use" component={TermsOfUse} />
          <Route path="/service-agreement" component={ServiceAgreement} />
        </>
      </Router>
    );
  }
}

export default App;
