import React from 'react';

const Dots = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.7 2.7">
    <path
      d="M8.5.3c-.6 0-1 .5-1 1 0 .6.5 1 1 1 .6 0 1-.5 1-1 .1-.5-.4-1-1-1zM5 .3c-.6 0-1 .5-1 1 0 .6.5 1 1 1 .6 0 1-.5 1-1s-.5-1-1-1zM1.4.3c-.6 0-1 .5-1 1 0 .6.5 1 1 1s1-.5 1-1-.5-1-1-1zm17.9 0c-.6 0-1 .5-1 1 0 .6.5 1 1 1 .6 0 1-.5 1-1s-.4-1-1-1zm-3.6 0c-.6 0-1 .5-1 1 0 .6.5 1 1 1 .6 0 1-.5 1-1s-.4-1-1-1zm-3.6 0c-.6 0-1 .5-1 1 0 .6.5 1 1 1 .6 0 1-.5 1-1s-.4-1-1-1z"
      fill="none"
      stroke="#000"
      strokeWidth=".7"
      strokeLinecap="round"
      strokeMiterlimit="10"
    />
  </svg>
);

export default Dots;
