import React from 'react';
import { Link } from 'react-router-dom';
import MiniLogo from '../media/svg/MiniLogo';
import CopyRight from './CopyRight';

const ServiceAgreement = () => {
  return (
    <div className="master lightOn" id="master">
      <div className="textContainer">
        <div className="header">
          <div className="header_inner">
            <Link to="/">
              <MiniLogo />
            </Link>
          </div>
        </div>
        <div>
          <h1>Service Agreement</h1>
          <div className="row">
            <div className="column">
              <div id=":mj" className="a3s aXjCH ">
                <p>Updated: January 24, 2019 </p>
                <p>
                  <b>PARTIES:</b> Simple Farms LLC. a Colorado limited liability
                  company (hereinafter referred to as “Company”), and
                  __________________________ (hereinafter referred to as
                  “Customer”). Company and Customer are sometimes referred to as
                  “Party” or collectively as “Parties.” in this Agreement.
                </p>
                <p>
                  <b>ACCEPTANCE: </b>By clicking “Accept” or by using or
                  accessing the System (defined below) and any related services,
                  you expressly agree to be bound by the terms, conditions,
                  policies, and guidelines of this Agreement.{' '}
                </p>
                <p>
                  IF YOU DO NOT AGREE TO ALL OF THE PROVISIONS OF THIS
                  AGREEMENT, YOU MAY NOT USE OR ACCESS THE SYSTEM AND ANY
                  RELATED SERVICES. YOU ACKNOWLEDGE THAT YOU HAVE READ, HAVE
                  UNDERSTOOD, AND AGREE TO BE BOUND BY THIS AGREEMENT USE JUST
                  AS IF YOU HAD SIGNED IT.
                </p>
                <p>
                  <b>RECITALS:</b>
                </p>
                <p>
                  A. Company owns proprietary software and documentation that
                  enables end users to access a suite of software solutions in
                  order to operate a successful agricultural business (such
                  software and any documentation provided is the “System”);
                </p>
                <p>
                  B. Customer desires to access the System and Company desires
                  to offer the System according to this Agreement.
                </p>
                <p>
                  <b>NOW, THEREFORE,</b> in consideration of the recitals,
                  covenants, and consideration set forth herein, the receipt and
                  adequacy of which is hereby acknowledged, the Parties agree as
                  follows:
                </p>
                <ol>
                  <li>
                    <u>System SaaS License</u>. Subject to the terms and
                    conditions of this Agreement, including the timely payment
                    of all fees due hereunder, Company hereby grants to Customer
                    a personal, non-exclusive, non-transferable right and
                    license to access and use the System over the internet.{' '}
                    <u>Exhibit&nbsp;A</u> lists the fees associated with this
                    Agreement and <u>Exhibit&nbsp;B</u> lists the minimum
                    equipment requirements, concurrent user limits, systems
                    limitations and storage limitations.
                  </li>
                  <li>
                    <u>Term and Termination</u>. This Agreement shall begin on
                    the date Customer accepts the terms of this Agreement and
                    terminate in 6&nbsp;months. Thereafter, this Agreement shall
                    renew for additional 6 month terms unless either Party
                    notifies the other Party in writing that it will not renew
                    at least thirty (30) days prior to the expiration of the
                    then-current term. Upon a material breach of this Agreement,
                    the non-breaching Party may send written notice to the
                    breaching Party informing the breaching Party of the nature
                    of the breach and providing thirty (30) days to cure a
                    non-monetary breach and ten (10) days to cure a monetary
                    breach (the “cure period”). If the breaching Party does not
                    cure the material breach within the cure period, the
                    non-breaching Party may immediately terminate this
                    Agreement.
                  </li>
                  <li>
                    <u>Restrictions</u>. Customer agrees that it will not, and
                    will not allow, its directors, officers, business partners,
                    or employees or agents to:
                  </li>
                  <ol>
                    <li>
                      Reverse assemble, reverse engineer, decompile or otherwise
                      attempt to derive source code from the System or any
                      component thereof;
                    </li>
                    <li>
                      Copy, reproduce, modify, sell, lease, sub-license, market
                      or commercially exploit in any way the System or any
                      component thereof (including the further distribution or
                      blank forms or templates) other than as expressly agreed
                      to in this Agreement;
                    </li>
                    <li>
                      Use, or permit the use of, the System except for
                      Customer’s internal purposes. Customer agrees that it
                      shall not provide access to or perform services for third
                      parties using the System including, but not limited to,
                      any service bureau, time-sharing, lease, distribution or
                      re-sale, rental, application service provider arrangement,
                      or any other arrangement; or
                    </li>
                    <li>
                      Disclose or grant access to an access code to the System
                      or any component thereof to any third party.
                    </li>
                    <li>
                      <u>Fees and Taxes</u>.
                    </li>
                    <li>
                      The fees for access and use of the System are set forth in{' '}
                      <u>Exhibit&nbsp;A</u>. Company will invoice Customer for
                      all fees. All amounts invoiced hereunder are due and
                      payable within thirty (30) days of the date of the
                      invoice;
                    </li>
                    <li>
                      Amounts which have not been paid according to invoice
                      shall be subject to a late payment at a rate of eighteen
                      percent (18%) per annum, payable monthly, on the amounts
                      outstanding from the time such amount becomes due until
                      payment is received by Company;
                    </li>
                    <li>
                      Customer agrees to pay any sales, value-added or other
                      similar taxes imposed by applicable law that Company must
                      pay based on this Agreement, except for taxes based on
                      Company’s income.
                    </li>
                    <li>
                      <u>Service Level Commitment</u>.
                    </li>
                    <li>
                      Except for those instances described below, Company shall
                      ensure that the System is available 95% of any calendar
                      month. Failure to achieve service availability shall not
                      constitute a material breach of this Agreement.{' '}
                    </li>
                    <li>
                      As its exclusive remedy, in the event the System
                      availability falls to below 95% for a given month for
                      Customer, Company will credit Customer’s account as
                      follows: (i)&nbsp;for 90% to 94.9% availability the credit
                      is fifty percent (50%) of the monthly fee; and
                      (ii)&nbsp;for 89.9% availability or below the credit is
                      one hundred percent (100%) of the monthly fee. This credit
                      will be in the form of partially extending the Term of
                      this Agreement and will not result in a refund of any
                      Customer fees due or previously paid. Notwithstanding the
                      forgoing, Company may, at its sole option, elect to refund
                      and Customer agrees to accept as its sole remedy, the
                      amount paid by Customer for System availability for a
                      given month.
                    </li>
                    <li>
                      Customer agrees to notify Company immediately if Customer
                      suspects the System is unavailable due to a fault of
                      Company. Customer agrees to provide reasonable information
                      as requested by Company for proper diagnosis or repair and
                      for proper calculation of any applicable credit.
                    </li>
                    <li>Credit does not apply as a result of:</li>
                    <ol>
                      <li>Unavailability due to scheduled maintenance;</li>
                      <li>
                        Periods of unavailability during which Customer’s
                        account is not in good financial standing or Customer is
                        in violation of this Agreement;
                      </li>
                      <li>
                        Circumstances beyond Company’s control, including,
                        without limitation, acts of any governmental body, war,
                        sabotage, embargo, fire, flood, extended unavailability
                        of utility service or unavailability or delay in
                        telecommunications, third party internet service
                        providers, co-location sites, or issues related to a
                        vendor’s or Customer’s business operations, equipment,
                        or third party servicers; or
                      </li>
                      <li>Customer’s misuse of the System.</li>
                      <li>
                        <u>Customer Support</u>.
                      </li>
                      <li>
                        Provided that Customer is current in its payment of fees
                        under this Agreement, Company shall provide to Customer
                        its standard technical support and maintenance, at no
                        additional charge. Company support personnel shall be
                        available to the Customer’s local administrator to
                        provide telephone support and assistance via e-mail and
                        other internet based technology directly to Customer’s
                        local administrator during Company’s normal business
                        hour (8:00 AM - 5:00 PM Mountain Time, M-F). Company
                        shall also provide standard error correction and
                        maintenance modifications to the System.
                      </li>
                      <li>
                        Notwithstanding the above, if Company makes a reasonable
                        business determination that the technical support
                        requested by Customer pursuant to this section will
                        entail detailed, specialized maintenance or support
                        services different in kind or amount from those provided
                        to other similar Customer of the System (including, but
                        not limited to, assistance to enable the interfacing or
                        operation with a non-supported, unusual, or proprietary
                        system complying with internal audits, or responding to
                        Sarbanes-Oxley audit requests), Company shall notify
                        Customer that the requested support is considered an
                        additional service which shall be subject to additional
                        fees, to be negotiated.
                      </li>
                      <li>
                        <u>System Maintenance</u>. Company shall operate and
                        maintain the System server (“Server”) in good working
                        order with access restricted to qualified employees or
                        contractors of Company and persons designated by
                        Customer. Company shall employ reasonable efforts to
                        ensure the security, confidentiality, and integrity of
                        all Customer data and other proprietary information
                        transmitted through or stored on the Server. Company
                        shall maintain backup information on all Customer data
                        in the application on a daily incremental basis during
                        the week and a full backup on the weekend.
                      </li>
                      <li>
                        <u>User Responsibilities, Password Protection</u>. As
                        part of the registration and account creation process,
                        Customer will need a “User Identification Name” and a
                        “Subscription Password.” Customer may not select a User
                        Identification Name which is identical to that used by
                        another person or use a User Identification Name which
                        is in the sole opinion of Company offensive or
                        inappropriate. Customer shall be solely responsible for
                        maintaining the confidentiality of Customer Subscription
                        Password. Customer is solely responsible for all usage
                        or activity on Customer’s account, including but not
                        limited to use of Customer’s account, Customer’s User
                        Identification Name, and Customer’s Subscription
                        Password by any third party. Any fraudulent, abusive, or
                        otherwise illegal activity may be grounds for
                        termination of Customer’s account, in Company’s sole
                        discretion, and Company may refer Customer to
                        appropriate law enforcement agencies.
                      </li>
                      <li>
                        <u>Customer Responsibilities</u>. Customer is
                        responsible for all activity occurring under its account
                        and will abide by all applicable local, state, national,
                        and foreign laws, treaties, and regulations in
                        connection with its access or use of the System,
                        including those related to data privacy, international
                        communications, and the transmission of technical or
                        personal data. Customer will be solely responsible for
                        ensuring that its users receive sufficient training to
                        enable proper access or use of the System. Customer will
                        be solely responsible for, and will bear the cost of,
                        providing all equipment, facilities, and connectivity,
                        including without limitation any internet access or
                        telecommunications services, necessary to use and access
                        the System.
                      </li>
                      <li>
                        <u>Provision of Releases</u>. At its sole option,
                        Company shall be entitled to prepare new versions of the
                        System that Company generally makes available to its
                        Customers (“Update Releases”). Company exclusively shall
                        determine whether Update Releases shall be included in
                        the System provided pursuant to this Agreement. At any
                        time, Company may install any Update Releases to provide
                        the services described herein, or develop new modules
                        for upgraded licenses at an additional cost.
                      </li>
                      <li>
                        <u>Right to Modify the System</u>. Company may from time
                        to time, in its sole discretion, change some or all of
                        the functionality or any component of the System or make
                        any modification for the purpose of improving the
                        performance, service quality, error correction, or to
                        maintain the responsiveness of the System.
                      </li>
                      <li>
                        <u>Confidential Information</u>.
                      </li>
                      <li>
                        Each Party acknowledges that confidential information
                        (including trade secrets and confidential technical,
                        financial and business information) (collectively,
                        “Confidential Information”) may be exchanged between the
                        Parties pursuant to this Agreement. Each Party shall use
                        no less than the same means it uses to protect its
                        similar confidential and proprietary information, but in
                        any event not less than reasonable means, to prevent the
                        disclosure and to protect the confidentiality of the
                        Confidential Information of the other Party. Each Party
                        agrees that it will not disclose or use the Confidential
                        Information of the other Party except for the purposes
                        of this Agreement and as authorized herein. Customer
                        will promptly report to Company any unauthorized use or
                        disclosure of Company’s Confidential Information that
                        the Customer becomes aware of and provide reasonable
                        assistance to Company (or its licensors) in the
                        investigation and prosecution of any such unauthorized
                        use or disclosure.
                      </li>
                      <li>
                        Notwithstanding Section&nbsp;12(a), the recipient of
                        Confidential Information may use or disclose the
                        Confidential Information to the extent that such
                        Confidential Information is: (i)&nbsp;already known by
                        the recipient without an obligation of confidentiality,
                        (ii)&nbsp;publicly known or becomes publicly known
                        through no unauthorized act of the recipient,
                        (iii)&nbsp;rightfully received from a third party
                        without any obligation of confidentiality,
                        (iv)&nbsp;independently developed by the recipient
                        without use of the Confidential Information of the
                        disclosing Party, (v)&nbsp;approved by the disclosing
                        Party for disclosure, or (vi)&nbsp;required to be
                        disclosed pursuant to a requirement of a governmental
                        agency or law so long as the recipient provides the
                        disclosing Party with notice of such requirement prior
                        to any such disclosure and takes all reasonable steps
                        available to maintain the information in confidence.
                      </li>
                      <li>
                        Customer shall safeguard and maintain the Confidential
                        Information of Company in strict confidence and shall
                        not, and shall cause all users not to, disclose,
                        provide, or make such Confidential Information or any
                        part thereof available in any form or medium to any
                        person except to the Customer’s employees, contractors,
                        and consultants who have a need to access such Company’s
                        Confidential Information in order to enable the Customer
                        to exercise its rights under this Agreement. The
                        Customer also agrees not to: (i)&nbsp;disclose to third
                        parties (whether in writing or orally) any benchmark
                        test data related to the System, and (ii)&nbsp;use
                        Company’s Confidential Information to create any
                        computer software or documentation that is substantially
                        similar to the System software.
                      </li>
                      <li>
                        <u>Data Issues</u>.
                      </li>
                      <li>
                        Customer will have sole responsibility for the accuracy,
                        quality, integrity, legality, reliability and
                        appropriateness of all data, information, or material
                        that Customer submits to the System during Customer’s
                        access or use of the System, including Customer’s
                        intellectual property ownership of and Customer’s right
                        (and that of the Users of Customer’s organization) to
                        access or use such data, information, or material
                        (collectively, “Customer Data”). Company does not own
                        any Customer Data.
                      </li>
                      <li>
                        Except as permitted in this Agreement, Company will not
                        edit, delete, or disclose the contents of Customer Data
                        unless authorized by Customer or unless Company is
                        required to do so by law or in the good faith belief
                        that such action is necessary to: (i)&nbsp;conform with
                        applicable laws or comply with legal process served on
                        Company; (ii)&nbsp;protect and defend the rights or
                        property of Company; or (iii)&nbsp;enforce this
                        Agreement.
                      </li>
                      <li>
                        Company may provide user statistical information such as
                        usage or traffic patterns in aggregate form to third
                        parties, but such information will not include
                        identifying information. Company may access Customer
                        Data to respond to service or technical problems with
                        the System.
                      </li>
                      <li>
                        Customer will be responsible or liable for the deletion,
                        correction, destruction, damage, loss, or failure to
                        store regarding any Customer Data.
                      </li>
                      <li>
                        Company reserves the right to establish a maximum amount
                        of memory or other computer storage and a maximum amount
                        of Customer Data that Customer may store, post, or
                        transmit on or through the System.
                      </li>
                      <li>
                        <u>Return of Confidential Information</u>. Upon the
                        termination of this Agreement for any reason whatsoever,
                        each Party may request of the other that all documents,
                        information, data, and/or software however recorded,
                        which contain any of the other’s Confidential
                        Information be returned, provided that the Party shall
                        be entitled to charge a reasonable fees and materials
                        charge for doing so. If no request is received for the
                        return of Confidential Information within thirty (30)
                        days of the termination of this Agreement, the
                        Confidential Information shall be destroyed within a
                        reasonable time thereafter and shall not be used for any
                        purpose whatsoever.
                      </li>
                      <li>
                        <u>Protection of Proprietary Rights</u>. Customer shall
                        not remove any proprietary, copyright, patent,
                        trademark, design right, trade secret, or any other
                        proprietary rights legends from the System.
                      </li>
                      <li>
                        <u>Intellectual Property Rights</u>. Company and its
                        licensors shall retain all ownership, title, copyright,
                        patent, trademark, and other proprietary rights in and
                        to the System and any component thereof, and all
                        content, features, and functionalities of the System.
                        Customer does not acquire any rights, express or
                        implied, in the System.
                      </li>
                      <li>
                        <u>Submissions</u>. Any information, materials,
                        suggestions, ideas, comments or other information
                        communicated by Customer to Company (the “Submission”)
                        will not be treated as confidential, proprietary, or
                        trade secret information. Through Customer’s Submission,
                        Customer hereby grants to Company the royalty-free,
                        perpetual, irrevocable, worldwide, non-exclusive right
                        and license to use, reproduce, modify, adapt, publish,
                        translate, create derivative works from, distribute,
                        perform and display the Submission, and to incorporate
                        any Submission in other works in any form, media, or
                        technology now known or later developed. Company will
                        not be required to treat any Submission as confidential
                        and may use any Submission in its business without
                        incurring any liability for royalties or any other
                        consideration of any kind, and will not incur any
                        liability as a result of any similarities that may
                        appear in future Company operations.
                      </li>
                      <li>
                        <u>Limited Warranty</u>. COMPANY, ITS SUPPLIERS, AND
                        LICENSORS WARRANT THAT THE SYSTEM AND ANY SERVICES
                        PROVIDED UNDER THIS AGREEMENT (HEREINAFTER COLLECTIVELY
                        REFERRED TO AS THE “SERVICES”) WILL SUBSTANTIALLY
                        CONFORM TO ANY SYSTEM DOCUMENTATION.{' '}
                      </li>
                      <li>
                        <u>Warranty Disclaimer</u>. EXCEPT AS EXPRESSLY PROVIDED
                        IN THE LIMITED WARRANTY, AND EXCEPT AS PROVIDED IN
                        SECTION&nbsp;23 HEREOF, COMPANY AND ITS LICENSORS
                        EXPRESSLY DISCLAIM ANY AND ALL REPRESENTATIONS,
                        WARRANTIES, AND CONDITIONS OF ANY KIND OR NATURE,
                        EXPRESS OR IMPLIED, WHETHER WRITTEN OR ORAL, INCLUDING
                        WITHOUT LIMITATION, REPRESENTATIONS, WARRANTIES AND
                        CONDITIONS OF SATISFACTORY QUALITY, PERFORMANCE,
                        MERCHANTABILITY, MERCHANTABLE QUALITY, DURABILITY,
                        FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                        NON-INFRINGEMENT AND THOSE ARISING BY STATUTE OR
                        OTHERWISE IN LAW OR FROM A COURSE OF DEALING OR USE OF
                        TRADE. COMPANY AND ITS LICENSORS DO NOT REPRESENT OR
                        WARRANT THAT: (A)&nbsp;THE SERVICES WILL MEET THE
                        CUSTOMER’S BUSINESS REQUIREMENTS; (B)&nbsp;THE SERVICES
                        WILL BE ERROR-FREE OR UNINTERRUPTED OR THAT THE RESULTS
                        OBTAINED FROM ITS USE WILL BE ACCURATE OR RELIABLE; OR
                        (C)&nbsp;ALL DEFICIENCIES ANY SERVICES OR THE SYSTEM CAN
                        BE FOUND OR CORRECTED.
                      </li>
                      <li>
                        <u>Exclusive Remedy</u>. EXCEPT FOR THE EXCLUSIVE REMEDY
                        AS PROVIDED ABOVE FOR SERVICE LEVEL COMMITMENTS, AND
                        EXCEPT FOR THE EXCLUSIVE REMEDY PROVIDED BY COMPANY FOR
                        INFRINGEMENT, FOR ANY BREACH OF WARRANTY OR THE FAILURE
                        OF COMPANY TO PROVIDE THE SERVICES AS REQUIRED HEREIN (A
                        “DEFICIENCY”), THE CUSTOMER’S SOLE AND EXCLUSIVE REMEDY
                        AND COMPANY’S ENTIRE OBLIGATION HEREUNDER SHALL BE, AT
                        COMPANY’S OPTION, FOR COMPANY TO CURE THE DEFICIENCY OR
                        FOR COMPANY TO REFUND AN AMOUNT EQUAL TO THE AMOUNT
                        CUSTOMER PAID FOR THE SYSTEM REDUCED BY ANY BENEFIT
                        RECEIVED BY CUSTOMER FROM THE SYSTEM.
                      </li>
                      <li>
                        <u>Limit of Liability</u>.
                      </li>
                      <li>
                        EXCEPT AS OTHERWISE PROVIDED HEREIN, FOR ANY BREACH OR
                        DEFAULT BY COMPANY OF ANY OF THE PROVISIONS OF THIS
                        AGREEMENT, OR WITH RESPECT TO ANY CLAIM ARISING HEREFROM
                        OR RELATED HERETO, COMPANY AND ITS LICENSORS’ ENTIRE
                        LIABILITY, IF ANY, SHALL IN NO EVENT EXCEED ANNUAL FEES
                        PAID TO COMPANY BY CUSTOMER PURSUANT TO THIS AGREEMENT
                        IN THE CALENDAR YEAR IN RESPECT OF WHICH THE CAUSE OF
                        ACTION FIRST AROSE. CUSTOMER ACKNOWLEDGES THAT THE
                        PRICES QUOTED HEREIN ARE CONSIDERATION FOR THE STATED
                        LIMITS OF LIABILITY IN THIS PARAGRAPH. IN NO EVENT WILL
                        COMPANY AND ITS LICENSORS BE LIABLE FOR SPECIAL,
                        INCIDENTAL, INDIRECT, OR CONSEQUENTIAL LOSS OR DAMAGE,
                        LOST BUSINESS REVENUE, LOSS OF PROFITS, LOSS OF DATA,
                        LOSS OF COVER, DAMAGES FOR DELAY, PUNITIVE OR EXEMPLARY
                        DAMAGES, FAILURE TO REALIZE EXPECTED PROFITS OR SAVINGS
                        OR ANY CLAIM AGAINST CUSTOMER BY ANY OTHER PERSON, EVEN
                        IF COMPANY AND ITS LICENSORS HAVE BEEN ADVISED OF THE
                        POSSIBILITY OF ANY SUCH LOSSES OR DAMAGES AND EVEN IF
                        THE REMEDY SET FORTH HEREIN<b> </b>SHALL BE DEEMED TO
                        HAVE FAILED OF ITS ESSENTIAL PURPOSE.
                      </li>
                      <li>
                        NO PARTY MAY BRING AN ACTION, REGARDLESS OF FORM,
                        ARISING OUT OF OR RELATED TO THIS AGREEMENT (OTHER THAN
                        TO RECOVER FEES OR EXPENSES DUE TO COMPANY) MORE THAN
                        ONE YEAR AFTER THE CAUSE OF ACTION HAS ARISEN OR THE
                        DATE OF DISCOVERY OF SUCH CAUSE, WHICHEVER IS LATER.
                      </li>
                      <li>
                        <u>Mutual Indemnification</u>. Subject to the provisions
                        contained herein and, with respect to Company, excluding
                        the indemnification described below for infringement,
                        each Party agrees to defend, indemnify and hold the
                        other and its officers, directors, agents, affiliates,
                        distributors, franchisees and employees harmless against
                        any loss, damage, expense, or cost, including reasonable
                        attorneys’ fees (including allocated costs for in-house
                        legal services) (“Liabilities”) arising out of any
                        claim, demand, proceeding, or lawsuit by a third party
                        relating to this Agreement, and due to the indemnifying
                        Party’s acts or omissions.
                      </li>
                      <li>
                        <u>
                          Company Indemnity and Exclusive Remedies for
                          Infringement
                        </u>
                        . In the event of a claim against Customer asserting or
                        involving an allegation that the System infringes upon
                        or violates any patent, copyright, trade secret, or
                        other proprietary or property right of any third person
                        or entity, then, as Customer’s exclusive remedy, Company
                        will defend, at Company’s expense, and will indemnify
                        Customer and hold Customer harmless against any loss,
                        cost, expense (including attorneys’ fees), or liability
                        arising out of such claim, whether or not such claim is
                        successful. In the event an injunction or order should
                        be obtained against use of the System by reason of the
                        allegations, or if in Company’s opinion the System is
                        likely to become the subject of such a claim of
                        infringement, Company will, at its option and in its
                        expense, and as Customer’s exclusive remedy,
                        (a)&nbsp;procure for the Customer the right to continue
                        using the System; (b)&nbsp;replace or modify the same so
                        that it becomes noninfringing (such modification or
                        replacement shall be functionally equivalent to the
                        original); or (c)&nbsp;if neither (a) nor (b) is
                        practicable, for all prepaid licensee fees, repurchase
                        the System from Customer on a depreciated basis
                        utilizing a straight line depreciation method,
                        commencing on the date of acceptance (or, if no fees
                        were prepaid, terminate the agreement). Notwithstanding
                        the foregoing, the Company will not indemnify the
                        Customer if the Customer alters the System or uses it
                        outside the scope of use identified in the Company’s
                        user documentation. The Company will not indemnify the
                        Customer to the extent that an infringement claim is
                        based upon (i)&nbsp;any information, design,
                        specification, instruction, software, data, or material
                        not furnished by the Company, or (ii)&nbsp;any system
                        from a third party portal or other external source that
                        is accessible to Customer within or from the Service (
                        <i>e.g.</i>, a third-party Web page accessed via a
                        hyperlink). Company will not indemnify Customer to the
                        extent that an infringement claim is based upon the
                        combination of any system with any products or services
                        not provided by Company. Company will not indemnify
                        Customer for infringement caused by Customer’s actions
                        against any third party if the System as delivered to
                        Customer and used in accordance with the terms of this
                        Agreement would not otherwise infringe any third party
                        intellectual property rights. Company will not indemnify
                        Customer for any infringement claim that is based on:
                        (1)&nbsp;a patent that Customer was made aware of prior
                        to the effective date of this Agreement (pursuant to a
                        claim, demand, or notice); or (2)&nbsp;Customer’s
                        actions prior to the effective date of this Agreement.
                        This section provides the Customer’s exclusive remedy
                        for any infringement claims or damages.
                      </li>
                      <li>
                        <u>Notice</u>. Any notice, approval, request,
                        authorization, direction or other communication under
                        this Agreement will be given in writing and will be
                        deemed to have been delivered and given for all purposes
                        (a)&nbsp;on the delivery date if delivered by confirmed
                        facsimile or email; (b)&nbsp;on the delivery date if
                        delivered personally to the Party to whom the same is
                        directed; (c)&nbsp;one business day after deposit with a
                        commercial overnight carrier, with written verification
                        of receipt; or (d)&nbsp;five business days after the
                        mailing date, if sent by U.S. mail, return receipt
                        requested, postage and charges prepaid, or any other
                        means of rapid mail delivery for which a receipt is
                        available. The contact information below the Parties
                        signatures may be used by the Parties hereto.
                      </li>
                      <li>
                        <u>Independent Contractors</u>. The Parties to this
                        Agreement are independent contractors. Neither Party is
                        an agent, representative or employee of the other Party.
                        Neither Party will have any right, power or authority to
                        enter into any agreement for or on behalf of, or incur
                        any obligation or liability of, or to otherwise bind,
                        the other Party. This Agreement will not be interpreted
                        or construed to create an association, agency, joint
                        venture or partnership between the Parties or to impose
                        any liability attributable to such a relationship upon
                        either Party.
                      </li>
                      <li>
                        <u>Amendments and Modifications</u>. No amendment,
                        modification, or supplement to this Agreement shall be
                        binding on any of the Parties unless it is in writing
                        and signed by the Parties in interest at the time of the
                        modification.
                      </li>
                      <li>
                        <u>Integration</u>. This Agreement and all Exhibits
                        hereto, as well as agreements and other documents
                        referred to in this Agreement constitute the entire
                        agreement between the Parties with regard to the subject
                        matter hereof and thereof. This Agreement supersedes all
                        previous agreements between or among the Parties. There
                        are no agreements, representations, or warranties
                        between or among the Parties other than those set forth
                        in this Agreement or the documents and agreements
                        referred to in this Agreement.
                      </li>
                      <li>
                        <u>Severability</u>. If any term or provision of this
                        Agreement is determined to be illegal, unenforceable, or
                        invalid in whole or in part for any reason, such
                        illegal, unenforceable, or invalid provisions or part
                        thereof shall be stricken from this Agreement, and such
                        provision shall not affect the legality, enforceability,
                        or validity of the remainder of this Agreement. If any
                        provision or part thereof of this Agreement is stricken
                        in accordance with the provisions of this section, then
                        this stricken provision shall be replaced, to the extent
                        possible, with a legal, enforceable, and valid provision
                        that is as similar in tenor to the stricken provision as
                        is legally possible.
                      </li>
                      <li>
                        <u>Consent to Jurisdiction</u>. The Parties hereto agree
                        that all actions or proceedings arising in connection
                        with this Agreement shall be tried and litigated
                        exclusively in the State and Federal courts located in
                        the State of Colorado.
                      </li>
                      <li>
                        <u>Choice of Law</u>. This Agreement shall be governed
                        by and construed under the laws of the State of Colorado
                        without consideration of its conflict of laws
                        provisions.
                      </li>
                      <li>
                        <u>Customer Reference</u>. Customer agrees (a)&nbsp;that
                        Company may identify Customer as a recipient of Services
                        and use Customer’s logo in sales presentations,
                        marketing materials, and press releases, and (b)&nbsp;to
                        develop a brief customer profile for use by Company on{' '}
                        <a
                          href={`${window.location.href}`}
                          data-saferedirecturl={`https://www.google.com/url?q=${
                            window.location.href
                          }.ag&amp;source=gmail&amp;ust=1547323252666000&amp;usg=AFQjCNHl9koDn6qR6NRAaNeAJ5vsyjaQBg`}
                        >
                          simplefarms.ag
                        </a>{' '}
                        or elsewhere online for promotional purposes.
                      </li>
                      <li>
                        <u>General Interpretation</u>. The terms of this
                        Agreement have been negotiated by the Parties hereto and
                        the language used in this Agreement shall be deemed to
                        be the language chosen by the Parties hereto to express
                        their mutual intent. This Agreement shall be construed
                        without regard to any presumption or rule requiring
                        construction against the Party causing such instrument
                        or any portion thereof to be drafted, or in favor of the
                        Party receiving a particular benefit under the
                        agreement. No rule of strict construction will be
                        applied against any person.
                      </li>
                    </ol>
                    <p />
                    <p>
                      <b>EXHIBIT&nbsp;A</b>
                    </p>
                    <p>
                      <b>FEE EXHIBIT</b>
                    </p>

                    <p>
                      <b>Annual Concierge Data Input Fee</b>: $200 per year
                    </p>
                    <p>
                      <b>Monthly Access Fee</b>: $750 per 6 month term.
                    </p>
                    <p>
                      <b>Tax:</b> All above prices do <u>not</u> include state
                      or local sales tax. Customer is required to pay any taxes
                      due. Company may (but is not obligated to) invoice
                      Customer for taxes and Customer shall pay the same.
                    </p>

                    <p />

                    <p>
                      <b>EXHIBIT B</b>
                    </p>
                    <p>
                      <b>CUSTOMER EQUIPMENT</b>
                    </p>
                    <p>
                      The recommended or minimum requirements for connecting to
                      the System are:
                    </p>
                    <p>Andriod 5.0 or higher</p>
                    <p>IoS 11.0 or higher</p>
                  </ol>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <CopyRight />
      </div>
    </div>
  );
};

export default ServiceAgreement;
