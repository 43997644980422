import React from 'react';

const MiniLogo = () => (
  <svg
    id="miniLogo_Isolation_Mode"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 158.8 63.3"
  >
    <path
      className="mini_logo_st0"
      d="M36.1 12.5c1.2 1.3 3.2 2.4 5.6 2.4 2.1 0 3.1-1 3.1-2 0-1.3-1.5-1.8-3.5-2.2-2.9-.7-6.5-1.4-6.5-5.4 0-2.9 2.5-5.3 6.7-5.3 2.8 0 5.1.8 6.8 2.4L46 5.2c-1.4-1.3-3.3-1.9-5-1.9-1.7 0-2.6.7-2.6 1.8 0 1.2 1.5 1.6 3.5 2 2.9.7 6.6 1.5 6.6 5.4 0 3.2-2.3 5.6-7 5.6-3.4 0-5.8-1.1-7.4-2.8l2-2.8zM58.1.3h3.7v17.5h-3.7V.3zM87.7 5.2l-4.9 12.6h-1.6L76.1 5.2v12.6h-3.7V.3h5.2l4.3 10.9L86.2.3h5.3v17.5h-3.8V5.2zM102 .3h8.2c3.8 0 5.9 2.6 5.9 5.7s-2.1 5.6-5.9 5.6h-4.5v6.3H102V.3zm7.7 3.2h-4v4.8h4c1.5 0 2.6-.9 2.6-2.4s-1.1-2.4-2.6-2.4zM125.5.3h3.7v14.3h7.4v3.3h-11.1V.3zM146.3.3h12.4v3.2H150v3.8h8.5v3.2H150v4.1h8.7v3.2h-12.4V.3z"
    />
    <path
      className="mini_logo_st1"
      d="M66.4 62.8h-3.8V38c0-7.4 6-13.4 13.4-13.4v3.8c-5.3 0-9.6 4.3-9.6 9.6v24.8z"
    />
    <g>
      <path
        className="mini_logo_st1"
        d="M39.8 24.5c-7.4 0-13.4 6-13.4 13.4v24.9h3.8V46.7h19.1v16.1h3.8V37.9c.1-7.4-5.9-13.4-13.3-13.4zm-9.6 18.3v-4.9c0-5.3 4.3-9.6 9.6-9.6s9.6 4.3 9.6 9.6v4.9H30.2z"
      />
    </g>
    <path
      className="mini_logo_st1"
      d="M113.3 24.5c-4.1 0-7.7 2.1-9.8 5.3-2.1-3.2-5.7-5.3-9.8-5.3-6.5 0-11.8 5.3-11.8 11.8v26.5h3.8V36.3c0-4.4 3.6-7.9 7.9-7.9 4.3 0 7.8 3.4 7.9 7.6v26.8h3.8V36.3 36c.2-4.2 3.6-7.6 7.9-7.6 4.4 0 7.9 3.6 7.9 7.9v26.5h3.8V36.3c.2-6.5-5.1-11.8-11.6-11.8zM13.4 3.9h13.7V.1H13.4C6 .1 0 6.1 0 13.5v49.3h3.8V31.6h17.6v-3.8H3.8V13.5c0-5.3 4.3-9.6 9.6-9.6zM144.4 40.8c-10.5-2.5-9.7-5.8-9.3-8 .7-3.4 4.7-5.2 10.6-4.7 6.7.5 9.2 3.8 9.3 3.9l.2-.1 2.4-2.8c-1.3-1.3-4.8-4.3-11.5-4.8-6.2-.5-13.3 1.3-14.7 7.7-1.4 6.4 2.4 10.2 12.2 12.5 11.2 2.6 11.3 5.6 11.3 8.2 0 1.4-.5 2.7-1.6 3.8-1.9 1.9-5.2 3-9.2 3-7.4 0-11-4.5-11.2-4.7l-2.5 2.9c1.5 1.5 6 5.5 13.7 5.5 5.1 0 9.3-1.5 12-4.2 1.8-1.8 2.8-4.1 2.7-6.5-.2-6.1-4.1-9.3-14.4-11.7z"
    />
  </svg>
);

export default (MiniLogo);
