import React from 'react';
import { Link } from 'react-router-dom';
import MiniLogo from '../media/svg/MiniLogo';
import CopyRight from './CopyRight';

const PrivacyPolicy = () => {
  return (
    <div className="master lightOn" id="master">
      <div className="textContainer">
        <div className="header">
          <div className="header_inner">
            <Link to="/">
              <MiniLogo />
            </Link>
          </div>
        </div>
        <div className="">
          <h1>Privacy Policy</h1>
          <div className="row">
            <div className="column">
              <div id=":mp" className="a3s aXjCH ">
                <p>Updated: January 24, 2019 </p>
                <p>
                  Simple Farms LLC (“<b>Simple Farms</b>” or “<b>we</b>” or “
                  <b>us</b>”) respects your privacy and is committed to
                  protecting it through our compliance with this privacy policy
                  (“<b>Privacy Policy</b>”).{' '}
                </p>
                <p>
                  We at Simple Farms constantly aim to provide the best user
                  experience possible to our customers. We value the long-term
                  relationship that allows us to provide a personalized,
                  valuable experience that delivers information, resources, and
                  services that are helpful to you. This Privacy Policy
                  discusses, among other things, how Simple Farms uses and
                  shares information and data related to you.{' '}
                </p>
                <p>
                  By using Simple Farms’ website (
                  <u>
                    <a
                      href={`${window.location.href}`}
                      data-saferedirecturl={`https://www.google.com/url?q=${
                        window.location.href
                      }&amp;source=gmail&amp;ust=1547574521567000&amp;usg=AFQjCNF5LrNZeN7sd8ZwxHdK5u4ekNaI_A`}
                    >
                      https://simplefarms.ag
                    </a>
                  </u>
                  ) (the “<b>Site</b>”), Simple Farms’ mobile applications (the
                  “<b>Apps</b>”), or Simple Farms’ services (which may be
                  available through the Site or the Apps) (the “<b>Services</b>
                  ”), you agree to the terms of this Privacy Policy and consent
                  to Simple Farms’ collection and use of information data
                  related to you.{' '}
                </p>
                <p>
                  If you do not agree or consent to any of the terms of this
                  Privacy Policy, your only recourse is to discontinue accessing
                  or otherwise using Apps, Site, and Services. Do not use the
                  Site, Apps, or Services if you do not agree with this Privacy
                  Policy.
                </p>
                <ol>
                  <li>Personal Data and Aggregate Information</li>
                </ol>
                <p>
                  “<b>Personal Data</b>” refers to information that tells us
                  specifically who you are, such as your name, phone number,
                  email, approximate geographic location of your computer or
                  device, or postal address, and possibly information relating
                  to certain support or customer service issues. While some
                  experiences, such as visiting the Site, will not prompt a
                  collection of Personal Data, you generally need to provide us
                  with Personal Data to use most of the features of the Site,
                  Apps, and Services, including to allow us to provide you with
                  certain personalized or enhanced Services. How we collect and
                  store information depends on the activities in which you elect
                  to participate and the Services provided.{' '}
                </p>
                <p>
                  From time to time, we may collect general, non-personal,
                  statistical information about the use of the Site, Apps, or
                  Services. This information represents a generic overview of
                  our users’ collective viewing habits and allows us and other
                  third parties to modify information, promotions, offers,
                  and/or discounts on Services based on user traffic and
                  behavior. We collect this information using technologies such
                  as “cookies,” which are discussed in greater detail below. We
                  collect this information to determine, for example, which
                  areas of the Site are most popular and to enhance the Site and
                  the Apps. We may also group this information into aggregate
                  data to describe the use of the Site or Apps to our existing
                  or potential business partners or other third parties, or in
                  response to a government request. We may also use this
                  information to optimize third-party offers of products and/or
                  services. We also may share or sell aggregated demographic and
                  preference data to third parties to enable the provision of
                  targeted information. However, please be assured that this
                  aggregate data in no way will it personally identify you or
                  any other user.
                </p>
                <ul>
                  <li>Information Simple Farms May Collect From You</li>
                </ul>
                <p>
                  Simple Farms receives and stores any information you enter on
                  our Site or Apps or give to us in any other way. Types of
                  information you may provide include:
                </p>
                <ul className="m_-8799270606776876171small">
                  <li>
                    <u>Contact Information</u>. When you sign up for an account,
                    you may be required to give us your name, email address,
                    street address, billing address, and phone number, and
                    additional information that enables you to sign up for and
                    use Services.{' '}
                  </li>
                </ul>
                <ul>
                  <li>
                    <u>Payment Information</u>. We may collect Personal Data
                    such as your credit card number or expiration date when you
                    sign up for Services or register with the Site or Apps.{' '}
                  </li>
                  <li>
                    <u>Information You Submit or Post</u>. We may collect
                    information you submit to us using our feedback tool or
                    website questionnaires. For example, when you contact
                    customer support, we may receive your name, email address,
                    phone number, the contents of a message or attachments that
                    you may send to us, and other information you choose to
                    provide.
                  </li>
                  <li>
                    <u>Phone Information</u>. If you have installed the Apps or
                    use the Site on your mobile device or tablet, it may require
                    special permission such as access to the camera, microphone,
                    pictures, read and write external storage, network access,{' '}
                    <i>etc.</i>, those permissions allow the Apps or Site to
                    work properly on your device. If you do not allow all
                    required permissions, the Apps or Site may not work
                    properly.
                  </li>
                  <li>
                    <u>Survey Information</u>. Simple Farms may also collect
                    information and data provided by you from our responses to
                    surveys. The surveys may be for research purposes to improve
                    our Site, Apps, or Services. Simple Farms may post
                    testimonials on our Site with express consent of the
                    individual or company who provided the information. We may
                    also post or share news, events, results, accomplishments,
                    marketing materials from Simple Farms or from other
                    individuals or companies to help promote their brand(s).
                    Requests to remove posted materials should be submitted to{' '}
                    <a href="mailto:privacy@simplefarms.ag">
                      privacy@simplefarms.ag
                    </a>
                    . Reasonable efforts will be made to remove posts upon
                    receiving written notice.
                  </li>
                  <li>Automatically Collected Information</li>
                </ul>
                <p>
                  When you use, install, visit, or access the Apps or the Site,
                  Simple Farms may collect certain information form you
                  automatically, such as information provided by your website
                  browser or mobile device, including but not limited to:
                </p>
                <ul>
                  <li>
                    <u>Device Identifiable Information</u>. We may collect
                    information that is linked to your computer or device. This
                    information may include your IP address, browser type and
                    language, access times, pages visited, and operating system.{' '}
                  </li>
                  <li>
                    <u>Mobile Device Information</u>. We may collect information
                    from any mobile device you use to access our Apps, Site, and
                    Services, including device type and model, network provider,
                    and unique device identifier.{' '}
                  </li>
                  <li>
                    <u>Cookies and Similar Technologies</u>. We may collect
                    technologies such as “cookies,” “pixels,” and “widgets.”{' '}
                  </li>
                </ul>
                <ul className="m_-8799270606776876171small">
                  <li>
                    Cookies are unique identifiers that we transfer to your
                    device to enable our systems to recognize your device and to
                    provide features, such as personalized advertisements on
                    other websites, and storage of items in your shopping cart
                    between visits. If you do not wish for Simple Farms to place
                    and use cookies on your computer, you may modify and control
                    how cookies are handled in your browser settings. If you
                    choose to disable cookies, certain functions of the Apps,
                    Site, and Services may not function correctly.{' '}
                  </li>
                </ul>
                <ul>
                  <li>
                    Pixels/Web Beacons (also known as Web bugs, pixel tags or
                    clear GIFs) are tiny graphics with a unique identifier that
                    may be included on our Services for several purposes,
                    including to deliver or communicate with Cookies, to track
                    and measure the performance of our Services, to monitor how
                    many visitors view our Services, and to monitor the
                    effectiveness of our advertising. Unlike cookies, which are
                    stored on the user’s hard drive, Web Beacons are typically
                    embedded invisibly on web pages (or in an e-mail).
                  </li>
                  <li>
                    Social Media Widgets such as a share button to Facebook or
                    Twitter may be on our Site. These features may collect your
                    IP address and what page you are visiting on our Website.{' '}
                  </li>
                  <li>
                    Google Analytics. We may also use Google Analytics and
                    Google Analytics Demographics and Interest Reporting to
                    collect information regarding visitor behavior and visitor
                    demographics on some of our Services, and to develop website
                    content. This analytics data is not tied to any Personal
                    Data.{' '}
                  </li>
                </ul>
                <p>
                  The Site do may not currently support Do Not Track, which is a
                  privacy preference you can set in your web browser to indicate
                  that you do not want certain information about your web page
                  visits tracked and collected across websites.{' '}
                </p>
                <ul>
                  <li>How We Use Your Information</li>
                </ul>
                <p>
                  We use, store, and process information, including Personal
                  Data about you, to provide, understand, improve, and develop
                  the Apps, Site, and Services. This information is collected
                  and used for the necessary purposes set out below:
                </p>
                <ul>
                  <li>
                    To carry out and provide our Services and obligations from
                    any contracts entered between you and Simple Farms,
                    including billing and collections.
                  </li>
                  <li>
                    Notify you of Service and subscription expirations and
                    renewals.
                  </li>
                  <li>
                    To provide customized, personalized content through our
                    Apps, Site, and Services.
                  </li>
                  <li>
                    Notify you about changes to our Apps, Site, Services or
                    policies.
                  </li>
                  <li>
                    To allow you to participate in new features and Services.
                  </li>
                  <li>
                    To facilitate the delivery of content or advertisements that
                    we feel may be relevant to you.
                  </li>
                  <li>
                    To communicate new or existing products or services that we
                    feel can help you.
                  </li>
                  <li>For our internal business purposes.</li>
                  <li>For any other purpose with your consent.</li>
                  <li>How We Share Information We Collect</li>
                </ul>
                <p>
                  Simple Farms customer information, user data, and Personal
                  Data may be shared with certain third party companies for the
                  purposes set forth below:{' '}
                </p>
                <ul>
                  <li>
                    Simple Farms may sometimes need to share your information
                    and data, including your Personal Data, to help facilitate
                    technical and administrative aspects of the Services (
                    <i>i.e.</i>, credit card processing, hosting, citation
                    building &amp; management). Simple Farms will take
                    reasonable steps to ensure that these service providers are
                    notified of their obligations to reasonably protect Personal
                    Data on Simple Farms’ behalf. Notwithstanding the above, we
                    cannot guarantee that such service providers will adhere to
                    the contractual obligations or acceptable business
                    practices.
                  </li>
                  <li>
                    We may provide your anonymized and aggregated Personal Data
                    to third parties for their marketing and/or advertising
                    purposes.
                  </li>
                  <li>
                    The Apps or Site may allow you to connect with other
                    websites, products, or services that we don’t have control
                    over. If you use these services, we will provide the third
                    party with information about you to allow them to provide
                    the service to you. We can’t speak to the privacy practices
                    of these third parties, and we encourage you to read their
                    privacy policies before deciding whether to use their
                    services.
                  </li>
                  <li>
                    Simple Farms may release information when we believe it is
                    necessary in order to comply with a legal obligation under
                    applicable law, or respond to valid legal process, such as a
                    search warrant, a court order, or a subpoena. We will also
                    share your Personal Data where we believe it is necessary
                    for the purpose of our own, or a third party’s legitimate
                    interest relating to national security, law enforcement,
                    litigation, criminal investigation, or protecting the safety
                    of any person, provided that such interest is not overridden
                    by your interests or rights and freedoms requiring the
                    protection of your Personal Data.{' '}
                  </li>
                  <li>
                    We may disclose certain Personal Data while negotiating or
                    in relation to a change of corporate control such as a
                    restructuring, merger, or sale of our assets. We may sell
                    information and Personal Data with any successor to all or
                    part of our business.{' '}
                  </li>
                  <li>
                    We may disclose Personal Data whenever you consent to the
                    sharing.{' '}
                  </li>
                  <li>Your Choices</li>
                </ul>
                <p>
                  You can always unsubscribe from our commercial or promotional
                  emails, but if you continue to use the Services, we may still
                  send you transactional and relational emails about your use of
                  the Services.
                </p>
                <p>
                  You can opt out of receiving push notifications through your
                  device settings. Please note that opting out of receiving push
                  notifications may impact your use of the Apps, Site, and
                  Services.
                </p>
                <p>
                  If you create an account to use the Apps, Site, and Services,
                  you may be asked to choose a password for your account, in
                  which case your account information shall be protected by your
                  password. You should not disclose your password to any other
                  person. You are responsible for maintaining the
                  confidentiality of your password and are fully responsible for
                  all activities that occur in your account. You can access and
                  update information in your account profile at any time by
                  logging into the account with your login name and password.
                  Once you have logged in, you can update certain information
                  such as your name, photo (if applicable), email, password,
                  billing information, and security preferences.
                </p>
                <p>
                  While you can prevent your device from sharing location
                  information at any time through your device’s operating system
                  settings. Your location is important to your use of the Apps,
                  Site, and Services and turning off your location information
                  sharing may affect your use of the Apps, Site, and Services.
                </p>
                <p>
                  If would like to terminate your account, please contact us
                  through our customer support with your request. If you choose
                  to terminate your account, we will deactivate it for you but
                  may retain information from your account for a certain period
                  of time and disclose it in a manner consistent with our
                  practices under this Privacy Policy for accounts that are not
                  closed. We also may retain information from your account to
                  collect any fees owed, resolve disputes, troubleshoot
                  problems, analyze usage of the Apps or Site, assist with any
                  investigations, prevent fraud, enforce any agreements between
                  Simple Farms and you, or take other actions as required or
                  permitted by law.
                </p>
                <ul>
                  <li>We Store Information in the United States</li>
                </ul>
                <p>
                  The nature of our business means that the Personal Data
                  collected through our Apps, Site, and Services will be
                  transferred to the United States. Information we maintain is
                  stored within the United States. If you live outside of the
                  United States, you understand and agree that we may transfer
                  your information to the United States. The Apps and Site are
                  subject to U.S. laws, which may not afford the same level of
                  protection as those in your country. Also, the Simple Farms
                  personnel and the third-parties to whom we disclose Personal
                  Data are located in the United States. We have not adopted
                  policies that comply specifically with the EU General Data
                  Protection Regulation (GDPR).{' '}
                </p>
                <ul>
                  <li>Miscellaneous</li>
                </ul>
                <p>
                  Simple Farms reserves the right, at its sole discretion, to
                  alter this Privacy Policy at any time without prior notice to
                  you or any user, including to comply with privacy laws,
                  regulations, and/or our own or industry standards, business
                  practices, or otherwise. We encourage you to review this
                  Privacy Policy each time you access or use the Apps, Site, or
                  Services. Your use of the Apps, Site, or Services at any time,
                  including after any change to this Privacy Policy, indicates
                  your acceptance of this Privacy Policy and any change made to
                  this Privacy Policy.
                </p>
                <p>
                  We take reasonable precautions to protect the privacy,
                  accuracy, and reliability of any information you provide and
                  to protect such information from loss, misuse, unauthorized
                  access, disclosure, alteration and destruction. Personal Data
                  is stored within databases that are controlled by us or by our
                  service providers. As we deem appropriate, we use security
                  measures consistent with industry standards, such as firewalls
                  and encryption technology, to protect your information.
                  However, since no security system is impenetrable, we cannot
                  guarantee the security of our databases, nor can we guarantee
                  that information you supply will not be intercepted while
                  being transmitted to us over the Internet. As a result, you
                  transmit any information to or from the Apps, Site, and
                  Services at your own risk.
                </p>
                <p>
                  We understand and share your concerns regarding your
                  children’s access to Internet and the Internet’s access to
                  your children. The Apps, Site, and Services are not directed
                  to children. Consistent with the Children’s Online Privacy
                  Protection Act of 1998 (COPPA), we do not knowingly request or
                  accept Personal Data submitted to any App or the Site by any
                  visitor under 13&nbsp;years of age. If we become aware that an
                  individual under the age of 13 has provided information to
                  Simple Farms through an App or the Site, we will make
                  commercially reasonable efforts to delete such information
                  from our databases.{' '}
                </p>
                <p>
                  If you live in California and have an established business
                  relationship with us, you can request a list of the Personal
                  Data we have shared with third parties and a list of the names
                  of third party with whom we have shared your Personal Data.
                  You may make such a request once per year. To make a request,
                  email us at{' '}
                  <a href="mailto:privacy@simplefarms.ag">
                    privacy@simplefarms.ag
                  </a>{' '}
                  with the subject line “California Shine the Light Inquiry.”
                </p>
                <p>
                  This Privacy Policy shall be governed by the laws of the State
                  of Colorado, without regard to conflicts of law principles.
                  Any action relating to this Privacy Policy must be filed and
                  maintained in a state or federal court of competent
                  jurisdiction located in Denver, Colorado, and you consent to
                  exclusive jurisdiction and venue in such courts for such
                  purpose. Simple Farms makes no representation that this
                  Privacy Policy and such practices comply with the laws of any
                  country outside the United States.
                </p>
                <p>
                  Any action, suit, or proceeding arising out of or in
                  connection with this Privacy Policy shall be in your and
                  Simple Farms’ individual capacity and not as a plaintiff or
                  class member in any purported class or representative
                  proceeding. You may not seek to have any dispute heard as a
                  class action, a representative action, or in any proceeding in
                  which you or Simple Farms acts or proposes to act in a
                  representative capacity. A judge may not consolidate or join
                  the claims of other persons or parties who may be similarly
                  situated without the written consent of you, Simple Farms, and
                  all other impacted parties. If any provision of this Privacy
                  Policy is held to be invalid or unenforceable, all remaining
                  provisions hereof will remain in full force and effect.{' '}
                </p>
                <p>
                  If you have any questions or comments about this Privacy
                  Policy or our privacy practices, or to report any violations
                  of this Privacy Policy or abuse of the Apples, Site, or
                  Services, please contact us at{' '}
                  <a href="mailto:privacy@simpleafarms.ag">
                    privacy@simpleafarms.ag
                  </a>
                  .
                </p>
                <div className="yj6qo" />
                <div className="adL">
                  <p> </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <CopyRight />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
