import React from 'react';

const GreenCircle = () => (
  <>
    <svg
      className="svgShape_outside"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 53.1 53.2"
    >
      <path
        d="M26.5 0C11.9 0 0 11.9 0 26.6s11.9 26.6 26.5 26.6H53V0H26.5z"
        fill="#757f4e"
      />
    </svg>

    <svg
      className="svgShape_inside"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 53.1 53.2"
    >
      <path
        d="M26.5 0C11.9 0 0 11.9 0 26.6s11.9 26.6 26.5 26.6H53V0H26.5z"
        fill="#778350"
      />
    </svg>
  </>
);

export default GreenCircle;
