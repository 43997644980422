import React from 'react';

const Location = () => (
  <svg id="sl_svg2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.6 10.5">
    <path
      className="sl_svg2_st0"
      d="M8.3 4.3c0 2.2-4 5.7-4 5.7s-4-3.5-4-5.7 1.8-4 4-4 4 1.8 4 4z"
    />
    <path
      className="sl_svg2_st0"
      d="M6.1 4.3c0 1-.8 1.8-1.8 1.8s-1.8-.8-1.8-1.8.8-1.8 1.8-1.8 1.8.8 1.8 1.8z"
    />
  </svg>
);

export default Location;
