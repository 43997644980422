import React from 'react';
const CopyRight = () => {
  return (
    <div className="copyright">
      <p>
        @ Copyright {` ${new Date().getFullYear()} `} Simple Farms LLC
        <br />
        <a href="/privacy-policy" title="Simple Farms Privacy Policy">
          Privacy Policy
        </a>{' '}
        |{' '}
        <a href="/terms-of-use" title="Simple Farms Terms of Use">
          Terms of Use
        </a>
        |{' '}
        <a href="/service-agreement" title="Simple Farms Service Agreement">
          Service Agreement
        </a>
      </p>
    </div>
  );
};

export default CopyRight;
