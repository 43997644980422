import React from 'react';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css';
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css/animate.min.css';
import './home.css';
import CopyRight from './CopyRight';
import logo from '../media/logo@2x.png';
import featured from '../media/featured.jpg';
import screenshots1 from '../media/screenshots1.png';
import screenshots2 from '../media/screenshots2.png';
import screenshots3 from '../media/screenshots3.png';
import desktop from '../media/desktop@2x.png';
import phone from '../media/phone@2x.png';
import MiniLogo from '../media/svg/MiniLogo';
import YellowCircle from '../media/svg/YellowCircle';
import Dots from '../media/svg/Dots';
import Gears from '../media/svg/Gears';
import Location from '../media/svg/Location';
import Report from '../media/svg/Report';
import GreenCircle from '../media/svg/GreenCircle';
import Modal from 'react-modal';

class Home extends React.Component {
  state = {
    showMenuBar: false,
    showHamburgerMenu: false,
    animateScreenshots: false,
    animateDevices: false,
    isOpen: false,
    modalIsOpen: true,
  };

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll);
  };
  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll);
  };
  // fadeIn = element => {

  // };
  handleScroll = event => {
    if (document.documentElement.scrollTop > 75) {
      this.setState({
        showMenuBar: true
      });
    } else {
      this.setState({
        showMenuBar: false
      });
    }
    if (document.documentElement.scrollTop > 1500) {
      this.setState({
        animateScreenshots: true
      });
    } else {
      this.setState({
        animateScreenshots: false
      });
    }
    if (document.documentElement.scrollTop > 2300) {
      this.setState({
        animateDevices: true
      });
    } else {
      this.setState({
        animateDevices: false
      });
    }
  };
  scrollDown = () => {
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: 900
    });
  };
  navigateTo = path => {
    const {
      location: { protocol, host }
    } = window;
    window.location.href = `${protocol}//app.${host}/${path}`;
  };
  loadVideo = () => {
    this.setState({
      isOpen: true
    });
  };
  render() {
    const {
      showMenuBar,
      animateScreenshots,
      animateDevices,
      isOpen
    } = this.state;
    return (
      <div className={`master ${showMenuBar ? 'lightOn' : null}`} id="master">
        {isOpen && (
          <>
            <button
              className="closeLightBox"
              onClick={() => this.setState({ isOpen: false })}
            >
              <i className="fas fa-times-circle" />
            </button>
            <Lightbox
              className="lightbox"
              mainSrc={
                <iframe
                  src="https://www.youtube.com/embed/zKzcpAoCQrI?rel=0&autoplay=1"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              }
              onCloseRequest={() => this.setState({ isOpen: false })}
            />
          </>
        )}
        <div className="welcome">
          <div className="topContainer">
            <div className="header">
              <div className="header_inner">
                <MiniLogo />
                <div className="header_buttons">
                  <button
                    className={`btn_md ${
                      showMenuBar ? '_colorTrans' : '_colorWhite'
                    } _bannerButton`}
                    onClick={() => this.navigateTo('login')}
                  >
                    LOG IN
                  </button>
                  <button
                    className={`btn_md ${
                      showMenuBar ? '_colorTrans' : '_colorWhite'
                    } _bannerButton`}
                    onClick={() => this.navigateTo('login?demo=true')}
                  >
                    DEMO
                  </button>
                  <button
                    className={`btn_md ${
                      showMenuBar ? '_colorTrans' : '_colorWhite'
                    } _bannerButton`}
                    onClick={() => this.navigateTo('register')}
                  >
                    BUY NOW
                  </button>
                </div>
              </div>
            </div>
            <div className="welcome_centered">
              <div className="logo">
                <img
                  src={logo}
                  width="150"
                  height="204"
                  alt="Simple Farms Logo"
                />
              </div>
              <h1>The first solution created for farmers, by a farmer.</h1>
              <p>
                An easy-to-use data collection tool and proven margin calculator
                that puts the power back in the hands of the producers.
              </p>
              <button
                className="btn_md _colorForest"
                onClick={() => this.navigateTo('register')}
              >
                BUY NOW
              </button>
              <div className="videoContainer">
                <button className="btn_video" onClick={this.loadVideo}>
                  <i className="fas fa-play-circle" /> WATCH VIDEO
                </button>
              </div>
            </div>
          </div>
          <ScrollAnimation animateIn="fadeIn" className="videoLogoContainer">
            <div>
              <button className="btn_video" onClick={this.loadVideo}>
                <i className="fas fa-play-circle whiteCircle" />
                <p style={{ color: '#fff' }}>Watch Video</p>
              </button>
            </div>
          </ScrollAnimation>
        </div>
        <div className="featured" id="featured">
          <h1>
            Like the impact of the moldboard plow in its day, we put the power
            back in the hands of farm owners to maintain a sustainable,
            profitable and growing business.
          </h1>
          <YellowCircle />
        </div>
        <div className="founded">
          <div className="founded_inner">
            <div className="founded_left">
              <span>FOUNDED BY A FARMER</span>
              <h1>We work where you work.</h1>
              <span className="separator _forest">
                <Dots />
              </span>
              <p>
                The Simple Farms App is an easy-to-use analytical tool designed
                to help you, the farmer, make quick informed decisions about
                input costs, break-evens, and key marketing decisions. It was
                designed by a farmer for farmers to save valuable time and
                money.
              </p>
              <p>
                Whether you grow one crop or multiple crops, the Simple Farms
                App will put the power of making informed decisions in the palm
                of your hand. Check the variables to see your potential profit
                margins with a few clicks.
              </p>
              <p>
                Join today and start improving your bottom line through
                knowledgeable decision making. Your banker, CPA and bookkeeper
                will appreciate it too!
              </p>
              <p style={{ fontStyle: 'italic' }}>
                Scott Scheimer
                <br />
                Simple Farms CEO
              </p>
            </div>
            <div className="founded_right">
              <img src={featured} alt="working the app" />
            </div>
          </div>
        </div>
        <div className="doing">
          <div className="doing_inner">
            <div className="doing_left" id="doing_left">
              <div
                className={`${
                  animateScreenshots ? 'animate_01' : null
                } layer_presentation_01`}
              >
                <img src={screenshots1} alt="app screenshot" />
              </div>
              <div
                className={`${
                  animateScreenshots ? 'animate_02' : null
                } layer_presentation_02`}
              >
                <img src={screenshots2} alt="app screenshot" />
              </div>
              <div
                className={`${
                  animateScreenshots ? 'animate_03' : null
                } layer_presentation_03`}
              >
                <img src={screenshots3} alt="app screenshot" />
              </div>
            </div>
            <div className="doing_right">
              <h1>Doing one thing well.</h1>
              <span className="separator _white">
                <Dots />
              </span>
              <p>
                Simple Farms App is a stand-alone tool to help farmers determine
                their break-even points. Unlike other agriculture Apps or
                programs, Simple Farms App focuses on determining a farmer's
                break-even by tracking field inputs.
              </p>
              <ol>
                <li>
                  <h5>Customize</h5>
                  <p>
                    Start your operation with details for your farm, not some
                    other farm's scenario.
                  </p>
                </li>
                <li>
                  <h5>Deploy</h5>
                  <p>
                    Once your farm's operation is set up, quickly begin each of
                    the field operations with a touch of a farm icon, tracking
                    each input on each field.
                  </p>
                </li>
                <li>
                  <h5>Succeed</h5>
                  <p>
                    The Simple Farms App calculates your break-even for each
                    operation. Next time you need to know what a field's
                    break-even is, search your Simple Farms App history to find
                    an informed answer.
                  </p>
                </li>
              </ol>
            </div>
          </div>
          <GreenCircle />
        </div>
        <div className="simple">
          <div className="simple_inner">
            <div className="simple_content">
              <div className="simple_content_inner">
                <h1>Simple. Efficient. Trusted.</h1>
                <span className="separator _forest">
                  <Dots />
                </span>
                <p>
                  The Simple Farms App is simple to use and takes the guesswork
                  out of profitability. With easy input, the Simple Farms App
                  can be trusted to give accurate data. Our technology transfers
                  the spreadsheet format into game-style icons, each
                  representing an input or operation.
                </p>
                <ul className="simple_list">
                  <li>
                    <Report />
                    <h5>ACCURATE REPORTING</h5>
                    <p>
                      Take advantage of rapidly changing market opportunities by
                      knowing your per bushel and per acre costs. Simple Farms
                      App gives the user the freedom of mobility. When you have
                      your mobile device with you, you have the tools to make a
                      quick, informed marketing decision.
                    </p>
                  </li>
                  <li>
                    <Location />
                    <h5>CUSTOM INPUTS</h5>
                    <p>
                      Base the decision to add more inputs on facts, not
                      guesswork. Does the cost truly translate to more profit?
                    </p>
                  </li>
                  <li>
                    <Gears />
                    <h5>FINANCIAL EFFICIENCIES</h5>
                    <p>
                      Save preparation time for financial documents and future
                      projections by keeping current data on your Simple Farms
                      App.
                    </p>
                  </li>
                  <li>
                    <div
                      className="simple_featured_display"
                      id="animate_simple"
                    >
                      <img
                        className={`${
                          animateDevices ? 'animateDesktop' : null
                        } displayDesktop`}
                        src={desktop}
                        alt="desktop app"
                      />
                      <img
                        className={`${
                          animateDevices ? 'animatePhone' : null
                        } displayPhone`}
                        src={phone}
                        alt="phone app"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="pricing">
          <div className="costBlurb section">
            <div className="content">
              <h3>Cost</h3>
              <span className="separator _forest">
                <Dots />
              </span>
              <p>
                Simple Farms cost structure allows the farmer to choose the
                right package for their farm entities.
              </p>
              <p>
                No time to input you data?
                <br />
                Simple Farms has a concierge service for your assistance!
              </p>
            </div>
          </div>
          <div className="section">
            <div className="farmPricing">
              <p>Introductory rate of</p>
              <h1>$750</h1>
              <p>/farm per six months</p>
            </div>
            <div className="conciergePricing">
              <p>+ Add Concierge Service</p>
              <h1>$200</h1>
              <p>/farm per six months</p>
            </div>
          </div>
        </div>

        <div className="discover">
          <div className="discover_inner">
            <h1>Discover the true value of your next yield.</h1>
            <p>
              The Simple Farms App empowers you with trusted information to
              become a more informed producer.
            </p>
            <span className="separator _center">
              <Dots />
            </span>

            <div className="connection">
              <button
                className="btn_md _colorForest"
                onClick={() => this.navigateTo('register')}
              >
                BUY NOW
              </button>
              <button
                className="btn_md outlinedBtn _colorTrans"
                onClick={() => this.navigateTo('contact-us')}
              >
                REQUEST INFO
              </button>
            </div>
          </div>
        </div>

        {/* Price change modal */}
        {/* <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={() => this.setState({ modalIsOpen: false })}
          className="Notification"
        >
          /
          <div className="close-btn">
            <button
              onClick={() => this.setState({ modalIsOpen: false })}
            >
              X
            </button>
          </div>

          
          <div className="logo">
            <img
              src={logo}
              width="150"
              height="204"
              alt="Simple Farms Logo"
            />
          </div>
          <h2>Starting April 1st, 2023, the price of a 6-month membership will increase to $750. Current customers and those who sign up prior to April will be grandfathered into the present 6-month price of $600.  This price increase is to ensure we are creating the best experience</h2>

          <div className="close-btn-wrapper">
            <button
              className='btn_md _colorForest'
              onClick={() => this.setState({ modalIsOpen: false })}
            >
              Close
            </button>
          </div>
        </Modal> */}          

        <CopyRight />
      </div>
    );
  }
}

export default Home;
