import React from 'react';

const Gears = () => (
  <svg id="sl_svg3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.1 9.9">
    <circle className="sl_svg3_st0" cx="4.5" cy="4.9" r="1.9" />
    <path
      className="sl_svg3_st0"
      d="M2.2 2.4l-1.1-.2c-.1 0-.3 0-.4.2L.4 3c-.1.1-.1.3 0 .4l.8.8c.1.1.1.1.1.2v1.1c0 .1 0 .2-.1.2l-.8.8c-.1.1-.1.3 0 .4l.4.7c0 .1.2.1.3.1l1.1-.3h.2l1 .6c.1 0 .1.1.2.2l.3 1.1c0 .1.2.2.3.2H5c.1 0 .3-.1.3-.2l.3-1.1c0-.1 0-.2.1-.2l.9-.5c.1 0 .2-.1.2 0l1.2.2c.1 0 .3 0 .4-.2l.4-.7c.1-.1.1-.3-.1-.4l-.8-.7c-.1-.1-.1-.1-.1-.2V4.4c0-.1 0-.2.1-.2l.8-.8c.1-.1.1-.3 0-.4l-.4-.7c0-.1-.2-.2-.3-.1l-1.1.2h-.2l-1-.5c-.1 0-.1-.1-.2-.2L5.2.6c0-.1-.2-.2-.3-.2h-.7c-.2-.1-.3 0-.3.2l-.3 1.1c0 .1-.1.2-.2.2l-.9.5c-.1.1-.2.1-.3 0z"
    />
  </svg>
);

export default Gears;
